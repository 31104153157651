import _ from "lodash";

import {
  getConfirmedOnDate,
  getBidSubmittedOnDate,
  getBidAcceptedOnDate,
  getActiveBidStatus
} from "../BidsDates"

import {
  getInstallationAppointmentDate,
  getInstalledOnDate
} from "../InstallationsDates"

export default function getRequestStatus(fetchedRequestData, translations) {

  const statuses = [
    {
      name: translations.assignment.statusName,
      active: false,
      value: 1,
      icon: "far fa-user-check",
      title: translations.assignment.title,
      declinedTitle: translations.assignment.declinedTitle,
      declinedSubtitle: translations.assignment.declinedSubtitle
    },
    {
      name: translations.quoting.statusName,
      active: false,
      value: 2,
      icon: "far fa-file-invoice-dollar",
      title: translations.quoting.title
    },
    {
      name: translations.approval.statusName,
      active: false,
      value: 3,
      icon: "far fa-stamp",
      title: translations.approval.title,
      revisedTitle: translations.approval.revisedTitle,
      revisedSubtitle: translations.approval.revisedTitle
    },
    {
      name: translations.scheduling.statusName,
      active: false,
      value: 4,
      icon: "far fa-calendar-day",
      title: translations.scheduling.title
    },
    {
      name: translations.activeSignOff.statusName,
      active: false,
      value: 5,
      icon: "far fa-pen-nib",
      title: translations.activeSignOff.title,
      inactiveTitle: translations.inactiveSignOff.title
    },
  ]

  let installedOnDates = [];
  let currentStatus = {};
  let findStatus = "";
  if (!_.isEmpty(fetchedRequestData.RequestLogs)) {
    //This is the first check, given the fact that if Bid is declined or rejected, this should take us to the assignment status screen to start the process over again.
    if (["BidDeclined", "BidRejected"].includes(_.last(fetchedRequestData.RequestLogs).LogType)) {
      findStatus = translations.assignment.statusName;
    }  else if (["BidReopened", "BidRequestReopened"].includes(_.last(fetchedRequestData.RequestLogs).LogType)){
      findStatus = translations.quoting.statusName;
    }  else if ( getActiveBidStatus(fetchedRequestData) == "reopened" ){
      findStatus = translations.approval.statusName;
    }
    //If we have data in the installations array, it means we have to be either in Scheduling or Sign-Off Status
    else if (!_.isEmpty(fetchedRequestData.Installations) && !_.isNil(fetchedRequestData.Installations)) {
      //Since it's possible to have more than one Bid or Installation, we have to look for the current active one; in this case we send no "status" parameter so we look for the active Bid
      //If we send no status parameter, the helper will filter the active Bid by default
      let bidConfirmedOnDate = getConfirmedOnDate(fetchedRequestData);
      let installationAppointmentDate = getInstallationAppointmentDate(fetchedRequestData);
      installedOnDates = getInstalledOnDate(fetchedRequestData);
      //Based on the existance of the desired dates, we define a status. 
      //This can be refactored in the future to use the helper functions inside the conditionals.
      if (installationAppointmentDate) findStatus = translations.activeSignOff.statusName;
      else if (bidConfirmedOnDate) findStatus = translations.scheduling.statusName;
    }
    //If we have data in the Bids array, it means we have to be either in Approval or Quoting Status
    else if (!_.isEmpty(fetchedRequestData.Bids)) {
      //Since it's possible to have more than one Bid or Installation, we have to look for the current active one; in this case we send no "status" parameter so we look for the active Bid
      //If we send no status parameter, the helper will filter the active Bid by default
      let bidSubmittedOnDate = getBidSubmittedOnDate(fetchedRequestData);
      let bidAcceptedOnDate = getBidAcceptedOnDate(fetchedRequestData);
      //Based on the existance of the desired dates, we define a status. 
      //This can be refactored in the future to use the helper functions inside the conditionals.
      if (bidSubmittedOnDate) findStatus = translations.approval.statusName;
      else if (bidAcceptedOnDate) findStatus = translations.quoting.statusName;
      else findStatus = translations.assignment.statusName;
      //If we have no data inside the Bids array, we have to be in the "Assignment" status and we're only going to have the survey SubmittedOn date too look for
    } else findStatus = translations.assignment.statusName;
  }
  //Based on the value of findStatus variable, we know what state is the request currently in, and we can go and find its attributes, so we can use them later
  currentStatus = statuses.find(status => status.name == findStatus);
  //Since the Sign-off state is actually divided into two sub-statuses, here we identify if we have to set the current status active value to true or false
  if ((currentStatus.name == translations.activeSignOff.statusName && installedOnDates) || currentStatus.name != translations.activeSignOff.statusName) {
    currentStatus.active = true;
  }
  return currentStatus;
}