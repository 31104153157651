import React from 'react';
import StyledComponents from './styles';
import { getBidServiceProviderName, getBidToken } from '../../../helpers/BidsDates';
import { getQuoteUrl } from './utils';

const { Card, TitleBox, IconBox, TitleIcon, TitleText, Details, DetailsText, ViewQuoteButton } = StyledComponents;

export default function ApproveQuoteCard({ requestData, translations, show }) {
  const serviceProviderName = getBidServiceProviderName(requestData);
  const id = getBidToken(requestData);

  const viewQuoteButtonUrl = requestData.QuoteBuilderCustomerUiEnabled
    ? getQuoteUrl(requestData)
    : `${window.location.origin}/installation-quote?&token=${id}`;

  if (!show) return null;
  return (
    <React.Fragment>
      <Card>
        <TitleBox>
          <IconBox>
            <TitleIcon className="far fa-stamp" />
          </IconBox>
          <TitleText>{translations.title}</TitleText>
        </TitleBox>
        <Details>
          <DetailsText>
            {translations.descriptionOne}
            {serviceProviderName}
            {translations.descriptionTwo}
            {requestData.WorkerType}.
            <br />
            <br />
            {translations.descriptionThree}
            <b>{requestData.Email}</b>.
          </DetailsText>
          <ViewQuoteButton as="a" href={viewQuoteButtonUrl} target="_blank" disabled={requestData.Status == 'Canceled'}>
            {translations.button}
          </ViewQuoteButton>
        </Details>
      </Card>
    </React.Fragment>
  );
}
